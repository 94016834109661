'use client'

import React, { useState, useEffect } from 'react';
import Image from 'next/image';

interface ColorRevealHeroProps {
    alt: string;
    className?: string;
}

const ColorRevealHero: React.FC<ColorRevealHeroProps> = ({
    alt,
    className = ''
}) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    return (
        <div className={`relative overflow-hidden ${className}`}>
            <Image
                src={'https://utfs.io/f/wXFHUNfTHmLjx8Sofk10N8h1XUbaCizIZsxcVSOg5DQfvwPe'}
                alt={alt}
                fill
                sizes="100vw"
                priority
                className={`absolute inset-0 w-full object-cover min-h-[600px] opacity-0 transition-opacity duration-300 ${isLoaded ? 'opacity-40' : ''
                    }`}
                onLoad={() => setIsLoaded(true)}
            />
            <div className='absolute bottom-0 bg-gradient-to-t from-background to-transparent h-20 md:h-40 w-full'></div>
        </div>
    );
};

export default ColorRevealHero;
